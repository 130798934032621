// Slider.js
import React  from 'react';
import "./styles/HeroSectionStyles.css";
import backgroundImage from '../components/assets/bgfinal.png';
import StartApplicationBtn from './StartApplicationBtn';
import CheckCertificationBtn from './CheckCertificationBtn';
import SupportUsBtn from './SupportUsBtn';


const HeroComponent = () => {
 

  const containerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#eef3ec',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%'
  };

  return (
    <div className="slider-container" style={containerStyle}>
      <div className="slider">
        <h2>A trustworthy source for halal Excellence</h2>
        <p>BHC GHANA is a Halal <span>auditing</span>, <span>training</span>, and <span>certification</span> body that promotes higher and inclusive Halal standards to meet the dietary preferences of Muslims and the ethically conscious Ghanaian consumers.</p>
      </div>
      <div className="hero-section-btns">
        <StartApplicationBtn />
        <CheckCertificationBtn />
        <SupportUsBtn />
      </div>
    </div>
  );
};

export default HeroComponent;
