import React, { useState } from 'react';
import "./styles/AboutStyles.css";
import "./styles/AboutBoardMembersStyles.css";
import Navbar from '../components/Navbar';
import Header from "../components/HeaderSection";
import Footer from "../components/Footer";
import JoinSideBar from '../components/JoinSideBar';
import ScrollToTopButton from '../components/ScrollToTopBtn';

const HowToGetCertified = () => {
  const [showInfoBar, setShowInfoBar] = useState(false); // State to control the visibility of the information bar

  // Function to handle click on the link
  const handleLinkClick = (e) => {
    e.preventDefault();
    setShowInfoBar(true); // Show the information bar
    // You can add any additional logic here, like tracking the click event or sending analytics
  };

  return (
    <div>
      <Navbar />
      <Header />
      <div className='section-container main-about'>
        <div className="about-wrapper">
          <main className="main ">
            <div className="main-sections governance">
              <p><strong>If you are a Butcher, Restaurant/Takeaway or Caterer and want to become HMC certified.</strong>, We would strongly advise that you download and read the application process guide (see link below) carefully first with all the relevant information contained within. (Especially page 5)</p>
              <p>Please click <strong><a href="/preapppack.pdf" target="_blank" rel="noopener noreferrer">here</a></strong> to download the BHC-GH Application Process Guidebook</p>
              
            </div>
          </main>
          <JoinSideBar />
        </div>
      </div>
      <Footer />
      <ScrollToTopButton />
    </div>
  );
}

export default HowToGetCertified;
