import React from 'react';
import { Helmet } from "react-helmet";
import "./styles/DonateStyles.css";
import Navbar from '../components/Navbar'
import Header from "../components/HeaderSection";
import Footer from "../components/Footer"
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ScrollToTopButton from '../components/ScrollToTopBtn';

const Donate = () => {

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <>
      <Helmet>
          <title>Support BHC Ghana</title>
        </Helmet>
      <Navbar />
      <Header />
      <div className='section-container main-about'>
        <div className="about-wrapper">
          <main className="main ">
            <div className="board-member-cards-container board-member-cards-container-main">
              Donate
            </div>
          </main>
        </div>
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  )
}

export default Donate
