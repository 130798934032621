import React from 'react';
import { Helmet } from "react-helmet";
import "./styles/AboutStyles.css";
import "./styles/AboutBoardMembersStyles.css"
import Navbar from '../components/Navbar';
import Header from "../components/HeaderSection";
import Footer from "../components/Footer";
import NewsEventsSidebar from '../components/NewsEventsSidebar';
import ScrollToTopButton from '../components/ScrollToTopBtn';
import { useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import newsData from "../components/MyNewsData"


const News = () => {

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <div>
        <Helmet>
          <title>News - BHC Ghana </title>
        </Helmet>
        <Navbar />
        <Header />
        <div className='section-container main-about'>
            <div className="about-wrapper">
                <main className="main ">
                  <div className="board-member-cards-container board-member-cards-container-main">
                    {newsData.map((news, index) => (
                      <NavLink to={`/news&events/news/${news.id}`}>
                      <div className="board-member-card board-member-card-main" key={index}>
                        <div className="board-member-img news-featured-img">
                          <img src={news.photograph} alt="Featured" />
                        </div>
                        <div className="board-member-details">
                          <h3>{news.title}</h3>
                          <p className="country">{news.excerpt}</p>
                        </div>
                      </div>
                      </NavLink>
                    ))}
                  </div>
                </main>
                <NewsEventsSidebar />
            </div>
        </div>
        <Footer />
        <ScrollToTopButton />
    </div>
  )
}

export default News