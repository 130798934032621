// AboutSection.js
import React from 'react';
import "./styles/AboutSectionStyles.css";
import { Link } from 'react-router-dom';
import teamData from './TeamData';


const AboutSection = () => {

  const targetNames = ["Professor Dr Anwar Sahib", "Sheikh Abdullatif Sheikh", "Dr Tamim Abdul Wahhab"];
  
  return (
    <div className="section-container about-section-container" >
      <div className="about-text">
        <h2 className="about-text-heading">Your Trusted Guardian of Halal Authenticity</h2>
        <p>The BHC-Ghana was incorporated to help ensure and assure that Muslims could be confident the meat and food products they consume are ‘purely Halal’. BHC-Ghana is an independent, non-profit, Ghanaian registered organization which monitors, inspects and certifies Halal products for the benefit of the Muslim community.</p>
        <p>
          <Link to={"/about/about-bhcghana"} className="more-link">More about us</Link>
        </p>
      </div>
      <div className="board-members">
        <div className="board-members-title">
          <h3>Meet Our Team</h3>
          <Link to={"/about/team/all-members"} className="more-link">More</Link>
        </div>

        <div className="board-member-cards-container">
          {teamData
            .filter(item => targetNames.includes(item.name))
            .map(item => (
              <Link to={`/about/team/${item.id}`} className="board-member-card" key={item.id}>
                <div className="board-member-img">
                  <img className="news-img" src={item.photograph} alt='newsimage' />
                </div>
                <div className="board-member-details">
                  <h4>{item.name}</h4>
                  <small>{item.position}</small>
                </div>
              </Link>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default AboutSection;