import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import LogoImage from "../components/assets/logopng.png"
import { CgMenuRight, CgClose } from "react-icons/cg";
import { Link } from 'react-scroll';
import { BsFacebook, BsTwitter, BsInstagram, BsWhatsapp } from "react-icons/bs";
import { BiSearch } from "react-icons/bi"
import { BsPlus} from "react-icons/bs"

const ScrollNavbar = () => {

    const [showSecondNavbar, setShowSecondNavbar] = useState(false);
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    useEffect(() => {
        const handleScroll = () => {
        if (window.scrollY > 650 || window.innerWidth <= 992) {
            setShowSecondNavbar(true);
        } else {
            setShowSecondNavbar(false);
        }
        };

        // Call handleScroll initially to handle the case when the screen width is initially less than 992px
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        // Clean up the event listeners when the component unmounts
        return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
        };
    }, []);


    const [isAboutDropdownOpen, setAboutDropdownOpen] = useState(false);
    const [isJoinDropdownOpen, setJoinDropdownOpen] = useState(false);
    const [isNewsDropdownOpen, setNewsDropdownOpen] = useState(false);

    const toggleAboutDropdown = () => {
      setAboutDropdownOpen(!isAboutDropdownOpen);
    };

    const toggleJoinDropdown = () => {
      setJoinDropdownOpen(!isJoinDropdownOpen);
    };

    const toggleNewsDropdown = () => {
      setNewsDropdownOpen(!isNewsDropdownOpen);
    };
  
    
    
    useEffect(() => {
      const grandchild = document.querySelector('.dropdown-link');
      const grandparent = document.querySelector('.dropdown');
      
      if (grandchild && grandchild.classList.contains('active-link')) {
        grandparent.classList.add('make-active');
      } else {
        grandparent.classList.remove('make-active');
      }
    }, []);
  
  
  
    const handleLink = (e) => {
      e.preventDefault(); // Prevent the default behavior of the event
      // Your custom logic here
    };

  return (
    <div>
        {showSecondNavbar && (
        <nav className="scroll-navbar">
          {/* Scroll navbar content */}
          <div className="scroll-navbar-header">
            <NavLink to="/">
              <div className="scroll-navbar-title">
                <div className="scroll-navbar-logo-container">
                  <img className="logo-img" src={LogoImage} alt="BHC-GHANA logo" />
                </div>
                <div className="scroll-navbar-title-text">BUREAU OF<br />HALAL CERTIFICATION<br />GHANA</div>
              </div>
            </NavLink>
            <ul className={click ? "navigation-menu active" : "navigation-menu"}>
              <div className="mobile-screen-search-container hide-container">
                <div className="search-icon-btn">
                  <BiSearch size={30}/>
                </div>
                <div className="search-input">
                  <input type="search" className="search-bar" placeholder="Search..." />
                </div>
              </div>
              <NavLink to="/"><li>Home</li></NavLink>

              <NavLink to="/about" className="dropdown" onMouseEnter={toggleAboutDropdown} onMouseLeave={toggleAboutDropdown} onClick={handleLink}>
                <li>About <BsPlus className="dropdown-icon" /></li>
                {isAboutDropdownOpen && (
                  <div className="dropdown-content">
                    <div class="triangle-up"></div>
                    <NavLink to="/about/about-bhcghana" className="dropdown-link">
                      About Us
                    </NavLink>
                    <NavLink to="/about/structure" className="dropdown-link">
                      Structure
                    </NavLink>
                    <NavLink to="/about/team/all-members" className="dropdown-link">
                      Governing Board
                    </NavLink>
                  </div>
                )}
              </NavLink>

              <NavLink to="/members"><li>Members</li></NavLink>

              <NavLink to="/news" className="dropdown join-dropdown" onMouseEnter={toggleJoinDropdown} onMouseLeave={toggleJoinDropdown} onClick={handleLink}>
                <li>Join BHC-GH <BsPlus className="dropdown-icon" /></li>
                {isJoinDropdownOpen && (
                  <div className="dropdown-content">
                    <div class="triangle-up"></div>
                    <NavLink to="/join/benefits" className="dropdown-link">
                     Benefits
                    </NavLink>
                    <NavLink to="/join/application-guide" className="dropdown-link">
                     Application Guide
                    </NavLink>
                    <NavLink to="/join/application-forms" className="dropdown-link">
                      Application Forms
                    </NavLink>
                    <NavLink to="/join/fees&charges" className="dropdown-link">
                      Fees and Charges
                    </NavLink>
                  </div>
                )}
              </NavLink>

              <NavLink to="/news" className="dropdown join-dropdown" onMouseEnter={toggleNewsDropdown} onMouseLeave={toggleNewsDropdown} onClick={handleLink}>
                <li>News & Events <BsPlus className="dropdown-icon" /></li>
                {isNewsDropdownOpen && (
                  <div className="dropdown-content">
                    <div class="triangle-up"></div>
                    <NavLink to="/news&events/news" className="dropdown-link">
                      News
                    </NavLink>
                    <NavLink to="/news&events/events" className="dropdown-link">
                      Events
                    </NavLink>
                    <NavLink to="/news&events/newsletter" className="dropdown-link">
                      Newsletter
                    </NavLink>
                  </div>
                )}
              </NavLink>
              
              <Link to="contact" smooth={true} duration={1000} className="contact-link">
                <li>Contact</li>
              </Link>

              <NavLink to="/donate"><li className="donate">Donate</li></NavLink>
              <div className="mobile-screen-socials-icons hide-container">
                  <a href="https://www.facebook.com/bhcghana" target="_blank" rel="noopener noreferrer" className='mobile-screen-icon'>
                    <BsFacebook size={30} />
                  </a>
                  <a href="https://twitter.com/bhc_ghana" target="_blank" rel="noopener noreferrer" className='mobile-screen-icon'>
                    <BsTwitter size={30}/>
                  </a>
                  <a href="https://www.instagram.com/bhc_ghana/" target="_blank" rel="noopener noreferrer" className='mobile-screen-icon'>
                    <BsInstagram size={30}/>
                  </a>
                  <a href="https://wa.me/+233534092123" target="_blank" rel="noopener noreferrer" className='mobile-screen-icon'>
                    <BsWhatsapp size={30}/>
                  </a>
              </div>
            </ul>
            <div className="hamburger" onClick = {handleClick}>
                {click ? (<CgClose size={30} style={{ color: "#1f3f32", transition: "1s"}}/>) : (<CgMenuRight size={30} style={{ color: "#1f3f32" , transition: "1s"}}/>)}  
            </div>
          </div>
        </nav>
      )}
    </div>
  )
}

export default ScrollNavbar