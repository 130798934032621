import React from 'react';
import "./styles/NavbarStyles.css";

import MainNavbar from './MainNavbar';
import ScrollNavbar from './ScrollNavbar';


function Navbar() {

  

  
  return (
    <div className="navbar-container">
      {/* Main Navbar */}
      <MainNavbar />
      {/* Second Navbar (conditionally visible) */}
      <ScrollNavbar />
    </div>
  );
}

export default Navbar
