import React from 'react';
import { Helmet } from "react-helmet";
import "./styles/AboutStyles.css";
import "./styles/AboutBoardMembersStyles.css"
import Navbar from '../components/Navbar';
import Header from "../components/HeaderSection";
import Footer from "../components/Footer";
import JoinSideBar from '../components/JoinSideBar';
import ScrollToTopButton from '../components/ScrollToTopBtn';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const JoinFeesCharges = () => {

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  
  return (
    <div>
        <Helmet>
          <title>Fees and Charges - BHC Ghana</title>
        </Helmet>
        <Navbar />
        <Header />
        <div className='section-container main-about'>
        <div className="about-wrapper">
                <main className="main ">
                  <div className="main-sections governance">
                    <p>BHC-GH is a non-profit organisation but operates to recover its costs associated with inspection and certification as well as its central administrative costs. Click <strong><a href="/services-charges.pdf" target="_blank" rel="noopener noreferrer">here</a></strong> to download our service charges.</p>
                    
                  </div>
                </main>
                <JoinSideBar />
            </div>
        </div>
        <Footer />
        <ScrollToTopButton />
    </div>
  )
}

export default JoinFeesCharges