// News.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles/NewsSectionStyles.css';
import newsData from "./MyNewsData"


const NewsSection = () => {
  return (
    <div className="section-container">
      <div className="news-header">
        <h2 className="section-title">Latest News</h2>
      </div>
      <div className="news-cards">

        {newsData.map((item) => (
          <NavLink to={`/news&events/news/${item.id}`} className="news-card">
              <div className="news-card-inner">
                <img className="news-img" src={item.photograph} alt="placeholder" />
                <div className="date-label">
                  <div className="date">{item.date}</div>
                  <div className="label">{item.category}</div>
                </div>
              </div>
              <div className="news-text">
                <h3 className="news-headline">{item.title}</h3>
                <p className="news-description">
                  {item.excerpt}
                </p>
              </div>
          </NavLink>
        ))}

        
        <div className="events-card">
          <h3 className="events-title">Upcoming Events</h3>
          <NavLink to="/news&events/events" className="event">
            <div className="event-date">
              <div className="event-date-day">05</div>
              <div className="event-date-month">DEC</div>
            </div>
            <h4>Ghana Halal and Tourism Promotion Conference</h4>
          </NavLink>

          <NavLink to="/news&events/events" className="event">
            <div className="event-date">
              <div className="event-date-day">01</div>
              <div className="event-date-month">NOV</div>
            </div>
            <h4>Hayes Awareness Programme – The Importance of Halal Food</h4>
          </NavLink>
          
          <NavLink to="/news&events/events" className="event">
            <div className="event-date">
              <div className="event-date-day">28</div>
              <div className="event-date-month">OCT</div>
            </div>
            <h4>Hayes Awareness Programme – The Importance of Halal Food</h4>
          </NavLink>
          
          <NavLink to="/news&events/events" className="event">
            <div className="event-date">
              <div className="event-date-day">30</div>
              <div className="event-date-month">SEP</div>
            </div>
            <h4>Halal Insights Webinar Series with Sheikh Abdullatif A. Sheikh</h4>
          </NavLink>
          
        </div>
      </div>
    </div>
  );
};

export default NewsSection;
