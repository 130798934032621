import React from 'react';
import { Helmet } from "react-helmet";
import "./styles/AboutStyles.css";
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import Header from "../components/HeaderSection";
import SideBar from '../components/AboutSideBar';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ScrollToTopButton from '../components/ScrollToTopBtn';


const AboutSubpage = () => {

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  
  return (
    <div>
      <Helmet>
          <title>About BHC Ghana</title>
        </Helmet>
      <Navbar />
      <Header />
      <div className="section-container main-about">
        <div className="about-wrapper">
        <main className="main">
          <div className="main-sections">
            <h2>A Trusted Guardian of Halal Authenticity...</h2>
            <p>The BHC-Ghana was incorporated to help ensure and assure that Muslims could be confident the meat and food products they consume are ‘purely Halal’. BHC-Ghana is an independent, non-profit, Ghanaian registered organization which monitors, inspects and certifies Halal products for the benefit of the Muslim community</p>

            <p>BHC Ghana works with companies, industries, Restaurants, Butcheries, Caterers and Meat Suppliers involved in the production and supply of Halal foods (including nutrient and supplements), to independently certify all products in other to provide assurance to the Muslim community that their food meets the high standards of Halal.</p>
          </div>
          <div className="main-sections">
            <h2 className="section-title">Our Mission</h2>
            <p>Our mission is to provide comprehensive Halal auditing, certification, training, and consulting services that empower businesses to meet the highest standards of Halal compliance. We monitor, inspect and certify Halal products and outlets for the benefit of the Muslim community.  We aim to educate and guide organizations to understand and implement Halal practices, publish and update record of companies who comply, thereby contributing to the well-being of diverse communities and promoting ethical and responsible business conduct.</p>            
          </div>
          <div className="main-sections">
            <h2 className="section-title">Our Vision</h2>
            <p>To be the most transparent, trustworthy and reliable Halal monitoring, inspection and Certification Company ensuring Halal integrity across industries, fostering trust and transparency in the market, and enhancing the quality of products and services for the benefit of consumers and businesses.</p>            
          </div>
          <div className="main-sections values-section">
            <h2 className="section-title">Core Values</h2>
            <div className="core-values">
              <h3>Integrity</h3>
              <p>We uphold the utmost integrity in all our actions, ensuring the credibility and reliability of our services to clients and stakeholders.</p>
            </div>  
            <div className="core-values">
              <h3>Transparency</h3>
              <p>We are committed to transparency in our processes, fostering trust between businesses and consumers in the Halal market.</p>
            </div>  
            <div className="core-values">
              <h3>Excellence</h3>
              <p>We strive for excellence in every aspect of our services, from audits to training, to ensure that our clients achieve the highest level of Halal compliance.</p>
            </div>
            <div className="core-values">
              <h3>Innovation</h3>
              <p>We continuously seek innovative ways to enhance our services, leveraging technology and industry best practices to provide cutting-edge solutions.</p>
            </div>
            <div className="core-values">
              <h3>Community Impact</h3>
              <p>We recognize our responsibility to the global community, working towards the betterment of society by promoting ethical business practices and ensuring access to genuinely Halal products and services.</p>
            </div>
            <div className="core-values">
              <h3>Education</h3>
              <p>We are dedicated to educating businesses about Halal principles, fostering a deeper understanding and appreciation for the significance of Halal practices.</p>
            </div>
            <div className="core-values values-last">
              <h3>Collaboration</h3>
              <p>We believe in collaborative partnerships, working closely with businesses, industry experts, regulatory bodies, and communities to collectively raise Halal standards.</p>
            </div>        
          </div>
        </main>
        <SideBar />
        </div>
      </div>
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default AboutSubpage
