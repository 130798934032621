import React from 'react'
import { NavLink } from 'react-router-dom';

const SupportUsBtn = () => {
  return (
    <NavLink to="/donate" style={{display: "flex"}} className="apply-support-link">
        <div className="start-application-link">
            Support Us
        </div>
    </NavLink>
  )
}

export default SupportUsBtn