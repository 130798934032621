import React from 'react'
import { NavLink } from 'react-router-dom';

const StartApplicationBtn = () => {
  return (
    <NavLink to="/join/application-forms" style={{display: "flex"}} className="apply-support-link">
        <div className="start-application-link">
            Start Application
        </div>
    </NavLink>
  )
}

export default StartApplicationBtn