import React from 'react'
import MainNav from './MainNav';
import MainNavbarHeader from './MainNavbarHeader';

const MainNavbar = () => {
  return (
    <nav className="main-navbar">
        {/* Main navbar content */}
        <MainNavbarHeader />
        <MainNav />
    </nav>
  )
}

export default MainNavbar