import React from "react";

import Homepage from "./routes/Homepage";
import Members from "./routes/Members";
import Resources from "./routes/Resources";
import News from "./routes/News";
import NewItem from "./routes/NewsItem";
import Donate from "./routes/Donate";
import About from "./routes/About"
import AboutUs from "./routes/AboutUs";

import { Route, Routes } from "react-router-dom";
import AboutAllMembers from "./routes/AboutAllMembers";
import AboutStructure from "./routes/AboutStructure";
import JoinGetCertified from "./routes/JoinGetCertified";
import JoinApplicationForms from "./routes/JoinApplicationForms";
import JoinFeesCharges from "./routes/JoinFeesCharges";
import Events from "./routes/Events";
import Newsletter from "./routes/Newsletter";
import HowToGetCertified from "./routes/HowToGetCertified";
import TeamMember from "./routes/TeamMember";
import AboutBoardMembers from "./routes/AboutBoardMembers";

function App() {
  return (
    <>
    <Routes>
      <Route path="/" exact element={<Homepage />}/>
      <Route exact path="/about" element={<About />} />
      <Route path="/about/about-bhcghana" exact element={<AboutUs />} />
      <Route path="/about/structure" exact element={<AboutStructure />} />
      <Route path="/about/team/all-members" exact element={<AboutAllMembers />} />
      <Route path="/about/team/board-members" exact element={<AboutBoardMembers />} />
      <Route path="/about/team/:memberId" exact element={<TeamMember />}/>
      <Route path="/members" exact element={<Members />}/>
      
      <Route path="/join/benefits" exact element={<JoinGetCertified />}/>
      <Route path="/join/application-guide" exact element={<HowToGetCertified />}/>
      <Route path="/join/application-forms" exact element={<JoinApplicationForms />}/>
      <Route path="/join/fees&charges" exact element={<JoinFeesCharges />}/>
      <Route path="/resources" exact element={<Resources />}/>
      <Route path="/news&events/news" exact element={<News />}/>
      <Route path="/news&events/news/:newsId" exact element={<NewItem />}/>
      <Route path="/news&events/events" exact element={<Events />}/>
      <Route path="/news&events/newsletter" exact element={<Newsletter />}/>
      <Route path="/donate" exact element={<Donate />}/>
    </Routes>
    </>
  );
}

export default App;
