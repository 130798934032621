import React from 'react';
import "./styles/AboutStyles.css";
import "./styles/AboutBoardMembersStyles.css"
import Navbar from '../components/Navbar';
import Header from "../components/HeaderSection";
import Footer from "../components/Footer";
import { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ScrollToTopButton from '../components/ScrollToTopBtn';
import teamData from "../components/TeamData";
import TeamSideBar from '../components/TeamSideBar';

const AboutBoardMembers = () => {

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);



  return (
    <div>
      <Navbar />
      <Header />
      <div className='section-container main-about'>
        <div className="about-wrapper">
          <main className="main ">
            <div className="team-members-container">
            {teamData
            .filter(item => item.position === 'Board Member') // Filter items with 'Board Member' position
            .map((item, index) => (
                <Link to={`/about/team/${item.id}`} className="team-member-card" key={item.id}>
                <div className="board-member-img">
                    <img className="news-img" src={item.photograph} alt='newsimage' />
                </div>
                <div className="board-member-details">
                    <h4>{item.name}</h4>
                    <small>{item.position}</small>
                </div>
                </Link>
            ))
            }
            </div>
          </main>
        <TeamSideBar />
        </div>
      </div>
      <Footer />
      <ScrollToTopButton />
    </div>
  )
}

export default AboutBoardMembers
