// AboutSection.js
import React from 'react';
import "./styles/CertifiedMembersSectionStyles.css"
import { Link } from 'react-router-dom';


const CertifiedMembersSection = () => {
  
  return (
    <div className="section-container certified-section" >
        <div className='certified-members-section'>
          <div className="certifed-section-text">
            <h2 className="section-title">Certified Members</h2>
            <p>BHC-GHANA is certifying  businesses and organizations in Ghana. List of those who have received our certification will be featured in this section of our homepage, which will be updated soon.</p>
            
            <small className="for-large-screen">
                <Link to={"/members"} className="more-link link-certified">See all certified members</Link>
            </small>
          </div>
          <div className="certified-members">
            <div className="certified-member-card">
                <div className="business-logo">logo</div>
                <div className="business-details">
                  <h4>Business Name</h4>
                  <small>Business Location Details</small>
                </div>
            </div>
            <div className="certified-member-card">
                <div className="business-logo">logo</div>
                <div className="business-details">
                  <h4>Business Name</h4>
                  <small>Business Location Details</small>
                </div>
            </div>
            <div className="certified-member-card">
                <div className="business-logo">logo</div>
                <div className="business-details">
                  <h4>Business Name</h4>
                  <small>Business Location Details</small>
                </div>
            </div>
          </div>
          <small className="for-small-screen">
              <Link to={"/members"} className="more-link link-certified">See all certified members</Link>
          </small>
        </div>
    </div>
  );
};

export default CertifiedMembersSection;