// Footer.js
import React, { useState } from 'react';
import "./styles/FooterStyles.css";
import { Link } from 'react-router-dom';
import { BsFacebook, BsTwitter, BsInstagram, BsWhatsapp } from "react-icons/bs";
import { Element } from 'react-scroll';

const Footer = () => {

  const [showInfoMessage, setShowInfoMessage] = useState(false);

  const handleFormClick = () => {
    setShowInfoMessage(true);
  };
  
  return (
    <>
    <div className="section-container footer-container" >
      <div className="footer-info-detail">
        <div className="footer-text">        
            <div className="footer-items-container">
              <div className="footer-items">
                <h6>LOCATION</h6>
                <ul>
                  <li>House No. #4, Akwei Allah Road</li>
                  <li>Madina-Accra</li>
                </ul>
              </div>
              <div className="footer-items">
                <h6>OFFICE HOURS</h6>
                <ul>
                  <li>MON - THUR (8:00 AM - 4:00PM)</li>
                  <li>FRIDAYS (2:00 AM - 5:00PM)</li>
                </ul>
              </div>
              <div className="footer-items">
                <h6>TELEPHONE</h6>
                <a className="contact-info-link" href="tel:+233534092123" target="_blank" rel="noopener noreferrer">
                  +233 53 409 2123
                </a><br /> 
                <a className="contact-info-link" href="tel:+233302541267" target="_blank" rel="noopener noreferrer">
                  +233 30 254 1267
                </a>
              </div>
              <div className="footer-items">
                <h6>EMAIL</h6>
                <p>bhcghana1.@gmail.com</p>
              </div>
              </div>
              <div className="footer-icons">
                  <div className='icon footer-icon'>
                    <a href="https://www.facebook.com/bhcghana" target="_blank" rel="noopener noreferrer">
                      <BsFacebook size={30}/>
                    </a>
                  </div>
                  <div className='icon footer-icon'>
                    <a href="https://twitter.com/bhc_ghana" target="_blank" rel="noopener noreferrer">
                      <BsTwitter size={30}/>
                    </a>
                  </div>
                  <div className='icon footer-icon'>
                    <a href="https://www.instagram.com/bhc_ghana/" target="_blank" rel="noopener noreferrer">
                      <BsInstagram size={30}/>
                    </a>
                  </div>
                  <div className='icon footer-icon'>
                    <a href="https://wa.me/+233534092123" target="_blank" rel="noopener noreferrer">
                      <BsWhatsapp size={30}/>
                    </a>
                  </div>
              </div>
              <div className="footer-copyright">© 2021 Bureau of Halal Certification - Ghana. All Rights Reserved</div>
            
        </div>
        <Element name="contact" className="form-container">
          <div  id="contact-us">
            <div className="form-header">
              <h2 className='section-title'>contact us</h2>
              <p>Fill out the form to submit any comment and/or enquiry that you may have and we'll respond to you within 24 hours.</p>
              {showInfoMessage && (
                <div className="info-message">
                  We are currently experiencing some challenges with the functionality of our form. For inquiries, please send us an email at <a href="mailto:bhcghana1@gmail.com"><strong>bhcghana1@gmail.com</strong></a>
                </div>
              )}
            </div>
            <div class="subscription" onClick={handleFormClick}>
              <form action="" className="form">
                <input 
                  type="text" 
                  name="name" 
                  className={`formStyle ${showInfoMessage ? 'disabled-input' : ''}`}
                  placeholder="Name (required)" 
                  required 
                  disabled={showInfoMessage}
                />

                <input 
                  type="email" 
                  name="email" 
                  className={`formStyle ${showInfoMessage ? 'disabled-input' : ''}`}
                  placeholder="Email (required)" 
                  required 
                  disabled={showInfoMessage} 
                />

                <input 
                  type="text" 
                  name="company-name" 
                  className={`formStyle ${showInfoMessage ? 'disabled-input' : ''}`}
                  placeholder="Name of Company you represent" 
                  required 
                  disabled={showInfoMessage}
                />

                <input 
                  type="text" 
                  name="text" 
                  className={`formStyle ${showInfoMessage ? 'disabled-input' : ''}`}
                  placeholder="Subject" 
                  required 
                  disabled={showInfoMessage} 
                />

                <textarea 
                  type="text" 
                  name="message" 
                  className={`formStyle ${showInfoMessage ? 'disabled-input' : ''}`} 
                  placeholder="Message (required)" 
                  disabled={showInfoMessage}
                ></textarea>

                <Link className={`submit-btn ${showInfoMessage ? 'disabled-btn' : ''}`} disabled={showInfoMessage}>Submit</Link>
              </form>
            </div>
          </div>
        </Element>
      </div>   
    </div>
    <div className="section-container webdesign">
        Web design and development by <a href="https://itsfeizl.github.io/" target="_blank" rel="noopener noreferrer">FEIZL</a>
      </div>
  </>
  );
};

export default Footer;
