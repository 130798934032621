import React from 'react'
import { NavLink } from 'react-router-dom';

const CheckCertificationBtn = () => {
  return (
    <NavLink to="/members" style={{display: "flex"}} className="apply-support-link">
        <div className="start-application-link support-us">
            Check Certification
        </div>
    </NavLink>
  )
}

export default CheckCertificationBtn