import featuredImage from "./assets/placeholder-img.jpg"

const newsData = [
    {
      id: '1',
      title: "Don't Compromise on Halal: Sheikh Abdullatif Urges Ghanaian Muslims",
      excerpt: "Sheikh Abdullatif of the Bureau of Halal Certification, Ghana, has encouraged Ghanaian Muslims not to compromise on Halal as it forms a cardinal part of their Muslim identities.",
      content: 
        [
          "Ghana, October 20th, 2023 – In a recent 'Halal Insights Webinar' hosted on September 30th, 2023, Sheikh Abdullatif, an esteemed member of the Bureau of Halal Certification in Ghana, passionately encouraged the Ghanaian Muslim community to steadfastly uphold the principles of Halal, emphasizing its integral role in their Muslim identities.",
          "The webinar, aptly titled 'an overview of what halal means, its origins, economic impact and its importance in the 21st century,' provided an insightful platform for Ghanaian Muslims to delve into the essential aspects of Halal living. Sheikh Abdullatif's address during this virtual event resonated deeply with the attendees, who gathered to gain a comprehensive understanding of the significance of Halal in their daily lives.",
          "Sheikh Abdullatif began his address by elucidating the origins of Halal and its historical context. He emphasized that Halal is rooted in the Quran and the teachings of the Prophet Muhammad, constituting a divine mandate for the Muslim community. He also highlighted the economic impact of Halal, explaining that it encompasses a wide range of industries, from food and beverages to cosmetics, pharmaceuticals, and tourism, contributing significantly to Ghana's economy.",
          "Moreover, Sheikh Abdullatif underlined the pivotal role of Halal in the 21st century, where Muslims face the challenge of maintaining their faith amidst a rapidly changing world. He urged the audience not to compromise on Halal principles and values, as it is a fundamental part of their Muslim identity and an assurance of the purity of their daily lives.",
          "The 'Halal Insights Webinar' was attended by a diverse audience, including scholars, students, entrepreneurs, and the general public, with an aim to strengthen the understanding and adherence to Halal practices. The event's success could be attributed to Sheikh Abdullatif's passionate and insightful address, which served as a source of inspiration and motivation for all attendees.",
          "In conclusion, Sheikh Abdullatif's call to the Ghanaian Muslim community not to compromise on Halal has reignited the commitment to Halal values and principles, reinforcing the importance of this fundamental aspect of their faith in the 21st century. The 'Halal Insights Webinar' proved to be an essential platform for knowledge dissemination and the promotion of a steadfast commitment to Halal practices among the Ghanaian Muslim community."
        ] ,
      photograph: featuredImage,
      date: "Oct 20",
      category: "News"
    },
    {
      id: '2',
      title: "BHC Ghana to Host Halal and Tourism Promotion Conference",
      excerpt: "The Bureau of Halal Certification, Ghana, is organizing a maiden Halal and Tourism Promotion Conference in Accra.",
      content: 
        [
          "The Bureau of Halal Certification, Ghana, has announced the organization of its inaugural 'Halal and Tourism Promotion Conference' in Accra. This conference, slated for December 5th, 2023, will take place at the prestigious Accra International Conference Center and is scheduled to commence at 9:00 AM.",
          "The Halal and Tourism Promotion Conference is poised to be a significant milestone for both the Bureau of Halal Certification and the Ghanaian tourism sector. This event aims to bring together key stakeholders from the Halal industry and the tourism sector to explore the potential synergy between these two domains and promote Ghana as a Halal-friendly tourist destination.",
          "The newly launched Bureau of Halal Certification, Ghana (BHC-GH), has embarked on a mission to advocate for and certify Halal products and services within the country. This conference underscores their commitment to furthering the understanding and promotion of Halal principles while exploring its integration with the booming tourism industry.",
          "During the conference, attendees can expect a range of informative sessions and discussions that encompass the significance of Halal practices, its economic implications, and its role in making Ghana an attractive destination for Muslim tourists. Distinguished speakers, industry experts, and government officials are expected to share their insights on how the Halal and tourism sectors can collaborate and benefit each other.",
          "The choice of the Accra International Conference Center as the venue reflects the significance of the event, offering a spacious and well-equipped location for participants to engage in meaningful discussions and networking opportunities.",
          "With Ghana's rich cultural heritage and diverse tourist attractions, the 'Halal and Tourism Promotion Conference' will serve as a crucial platform to showcase the country's potential as a preferred destination for Muslim travelers. This conference will also serve as an educational opportunity for local businesses and the tourism industry to understand the benefits of Halal certification, further promoting Ghana as a welcoming and inclusive destination for all.",
          "The event promises to be a groundbreaking occasion, uniting the realms of Halal and tourism in Ghana, and fostering collaboration that will enhance the country's appeal to the global Muslim traveler community. Participants and attendees can look forward to a day filled with insights, knowledge exchange, and opportunities for the growth of both sectors."
        ],
      photograph: featuredImage,
      date: "Oct 1",
      category: "News"
    },
  ]


  export default newsData;