import React from 'react'
import "./styles/ResourcesStyles.css";
import Navbar from '../components/Navbar'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Resources = () => {

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <>
        <Navbar />
        <div className='resources-page-container'>
            <h1>Resources Page</h1>
            <p>This page is still being developed.</p>
        </div>
    </>
  )
}

export default Resources
