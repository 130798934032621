import React from 'react'
import { MdPhone } from "react-icons/md";
import { HiMail, HiLocationMarker } from "react-icons/hi";

const HeaderContactDetails = () => {
  return (
    <div>
        <div className="contact-info-container">
            <div className='contact-info'>
              <div className="contact-info-detail">
                House #4, Akwei Allah Rd, Madina - Accra
              </div>
              <HiLocationMarker className="contact-info-icon" size={20}/>
            </div>
            <div className='contact-info'>
              <div className="contact-info-detail">
                <a className="contact-info-link" href="tel:+233534092123" target="_blank" rel="noopener noreferrer">
                  +233 53 409 2123
                </a> / 
                <a className="contact-info-link" href="tel:+233302541267" target="_blank" rel="noopener noreferrer">
                  +233 30 254 1267
                </a>
              </div>
              <MdPhone className="contact-info-icon" size={20}/>
            </div>
            <div className='contact-info'>
              <div className="contact-info-detail">
                <a className="contact-info-link" href="mailto:info@bhcghana.org" target="_blank" rel="noopener noreferrer">
                  bhcghana1@gmail.com
                </a>
              </div>
              <HiMail className="contact-info-icon" size={20}/>
            </div>
          </div>
    </div>
  )
}

export default HeaderContactDetails