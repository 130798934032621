// Blog.js
import React from 'react';
import { Helmet } from "react-helmet";
import Navbar from '../components/Navbar';
import Header from "../components/HeaderSection";
import Footer from "../components/Footer";
import { useParams } from 'react-router-dom';
import "./styles/NewsStyles.css"
import { getBlogContentById } from '../components/NewsService'; // Import the function from your data service module
import NewsEventsSidebar from '../components/NewsEventsSidebar';


function NewItem() {
  // Get the blog ID from the URL
  const { newsId } = useParams();

  // Use the blogId to fetch and display the blog content
  // You can fetch data from an API or use a data source as per your setup
  const blogContent = getBlogContentById(newsId);

  return (
    <div>
        <Helmet>
          <title>News - BHC Ghana </title>
        </Helmet>
        <Navbar />
        <Header />
        <div className='section-container main-about'>
            <div className="about-wrapper">
                <main className="main ">
                    <div className="main-sections governance">
                        <img src={blogContent.photograph} alt="Featured"/>
                        
                            <h2 className="news-title">{blogContent.title}</h2>
                            <p className="news-content"><strong>{blogContent.excerpt}</strong></p>
                            {Array.isArray(blogContent.content)
                            ? blogContent.content.map((paragraph, index) => (
                                <p key={index}  className="news-content">{paragraph}</p>
                                ))
                            : <p className="news-content">{blogContent.content}</p>}
                        
                    </div>
                </main>
                <NewsEventsSidebar />
            </div>
        </div>
        <Footer />
    </div>
  );
}

export default NewItem;
