import React from 'react'
import { NavLink } from 'react-router-dom';
import LogoImage from "../components/assets/logopng.png"

const MainLogoTitle = () => {
  return (
    <div>
        <NavLink to="/" className="main-navbar-title-link">
            <div className="main-navbar-title">
              <div className="main-navbar-logo-container">
                <img className="logo-img" src={LogoImage} alt="BHC-GHANA logo" />
              </div>
              <div className="Main-navbar-title-text">
                <div className="main-navbar-name">
                  BUREAU OF<br />HALAL CERTIFICATION<br />GHANA
                </div>
              </div>
            </div>
        </NavLink>
    </div>
  )
}

export default MainLogoTitle