import React, { useState, useEffect } from 'react';
import "./styles/NavbarStyles.css";
import { NavLink } from 'react-router-dom';
import { BsFacebook, BsTwitter, BsInstagram, BsWhatsapp } from "react-icons/bs";
import { BiSearch } from "react-icons/bi"
import { Link } from 'react-scroll';

const MainNav = () => {
    

  const [isAboutDropdownOpen, setAboutDropdownOpen] = useState(false);
  const [isJoinDropdownOpen, setJoinDropdownOpen] = useState(false);
  const [isNewsDropdownOpen, setNewsDropdownOpen] = useState(false);

  const toggleAboutDropdown = () => {
    setAboutDropdownOpen(!isAboutDropdownOpen);
  };

  const toggleJoinDropdown = () => {
    setJoinDropdownOpen(!isJoinDropdownOpen);
  };

  const toggleNewsDropdown = () => {
    setNewsDropdownOpen(!isNewsDropdownOpen);
  };

  
  
  useEffect(() => {
    const grandchild = document.querySelector('.dropdown-link');
    const grandparent = document.querySelector('.dropdown');
    
    if (grandchild && grandchild.classList.contains('active-link')) {
      grandparent.classList.add('make-active');
    } else {
      grandparent.classList.remove('make-active');
    }
  }, []);



  const handleLink = (e) => {
    e.preventDefault(); // Prevent the default behavior of the event
    // Your custom logic here
  };

  return (
    <div>
        <div className="main-navigation-bar">
          <div className="navigation-menus">
            <ul className="navigation-menu">
              <NavLink to="/" className={({isActive}) => isActive ? "active-link": "non-active-class" }>
                <li>Home</li>
              </NavLink>

              <NavLink to="/about" className={({isActive}) => isActive ? "active-link dropdown": "non-active-class dropdown" } onMouseEnter={toggleAboutDropdown} onMouseLeave={toggleAboutDropdown} onClick={handleLink}>
                <li>About</li>
                {isAboutDropdownOpen && (
                  <div className="dropdown-content">
                    <div class="triangle-up"></div>
                    <NavLink to="/about/about-bhcghana" className="dropdown-link">
                      About Us
                    </NavLink>
                    <NavLink to="/about/structure" className="dropdown-link">
                      Structure
                    </NavLink>
                    <NavLink to="/about/team/all-members" className="dropdown-link">
                      Governing Board
                    </NavLink>
                  </div>
                )}
              </NavLink>   
              
              <NavLink to="/members" className={({isActive}) => isActive ? "active-link": "non-active-class" }>
                <li>Members</li>
              </NavLink>

              <NavLink to="/join" className={({isActive}) => isActive ? "active-link dropdown": "non-active-class dropdown" } onMouseEnter={toggleJoinDropdown} onMouseLeave={toggleJoinDropdown} onClick={handleLink}>
                <li>Join BHC-GH</li>
                {isJoinDropdownOpen && (
                  <div className="dropdown-content">
                    <div class="triangle-up"></div>
                    <NavLink to="/join/benefits" className="dropdown-link">
                      Benefits
                    </NavLink>
                    <NavLink to="/join/application-guide" className="dropdown-link">
                     Application Guide
                    </NavLink>
                    <NavLink to="/join/application-forms" className="dropdown-link">
                      Application Forms
                    </NavLink>
                    <NavLink to="/join/fees&charges" className="dropdown-link">
                      Fees and Charges
                    </NavLink>
                  </div>
                )}
              </NavLink>

              <NavLink to="/news&events" className={({ isActive }) => (isActive ? "active-link dropdown" : "non-active-class dropdown")} onMouseEnter={toggleNewsDropdown} onMouseLeave={toggleNewsDropdown} onClick={handleLink}>
                <li>News & Events</li>
                {isNewsDropdownOpen && (
                  <div className="dropdown-content">
                    <div class="triangle-up"></div>
                    <NavLink to="/news&events/news" className="dropdown-link">
                      News
                    </NavLink>
                    <NavLink to="/news&events/events" className="dropdown-link">
                      Events
                    </NavLink>
                    <NavLink to="/news&events/newsletter" className="dropdown-link">
                      Newsletter
                    </NavLink>
                  </div>
                )}
              </NavLink>
              
              <Link to="contact" smooth={true} duration={1000} className="contact-link">
                <li>Contact</li>
              </Link>

              <NavLink to="/donate" className={({isActive}) => isActive ? "active-link": "non-active-class" }>
                <li>Donate</li>
              </NavLink>
            </ul>
            <div className="socials-icons">
              <div className='icon'>
                <a href="https://www.facebook.com/bhcghana" target="_blank" rel="noopener noreferrer">
                  <BsFacebook size={20}/>
                </a>
              </div>
              <div className='icon'>
                <a href="https://twitter.com/bhc_ghana" target="_blank" rel="noopener noreferrer">
                  <BsTwitter size={20}/>
                </a>
              </div>
              <div className='icon'>
                <a href="https://www.instagram.com/bhc_ghana/" target="_blank" rel="noopener noreferrer">
                  <BsInstagram size={20}/>
                </a>
              </div>
              <div className='icon'>
                <a href="https://wa.me/+233534092123" target="_blank" rel="noopener noreferrer">
                  <BsWhatsapp size={20}/>
                </a>
              </div>
            </div>
          </div>
          <div className="search-container">
            <div className="search-icon-btn">
              <BiSearch size={25}/>
            </div>
            <div className="search-input">
              <input type="search" className="search-bar" placeholder="Search..." disabled/>
            </div>
          </div>
        </div>
    </div>
  )
}

export default MainNav