import React from 'react';
import { Helmet } from "react-helmet";
import "./styles/AboutStyles.css";
import "./styles/AboutBoardMembersStyles.css"
import "./styles/NewsStyles.css"
import Navbar from '../components/Navbar';
import Header from "../components/HeaderSection";
import Footer from "../components/Footer";
import NewsEventsSidebar from '../components/NewsEventsSidebar';
import ScrollToTopButton from '../components/ScrollToTopBtn';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Events = () => {

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const eventsData = [
    {
      title: "Ghana Halal and Tourism Promotion Conference",
      details: 
        { 
          date: "5th December, 2023",
          venue: "Accra International Conference Center",
          time: "9:00 AM"
        }
    },
    {
      title: "Awareness Programme – The Importance of Halal Food Part II",
      details: 
        { 
          date: "1st November, 2023",
          venue: "Webinar @ webinar.link",
          time: "9:00 AM"
        }
    },
    {
      title: "Awareness Programme – The Importance of Halal Food Part I",
      details: 
        { 
          date: "October 28th, 2023",
          venue: "Webinar @ webinar.link",
          time: "2:00 PM"
        }
    },
    {
      details: 
        { 
          date: "September 30, 2023",
          venue: "Webinar @ webinar.link",
          time: "10:00 AM"
        }
    }
  ]

  return (
    <div>
        <Helmet>
          <title>Events - BHC Ghana </title>
        </Helmet>
        <Navbar />
        <Header />
        <div className='section-container main-about'>
            <div className="about-wrapper">
                <main className="main ">
                    {eventsData.map((event, index) => (
                      <div className="main-sections">
                        <h2>{event.title}</h2>
                        <ul>
                            <li>
                              <strong>Date: </strong> 
                              <span className="event-details">{event.details.date}</span>
                            </li>
                            <li>
                              <strong>Venue:</strong> 
                              <span className="event-details">{event.details.venue}</span>
                            </li>
                            <li>
                              <strong>Time:</strong> 
                              <span className="event-details">{event.details.time}</span>
                              </li>
                        </ul>
                      </div>
                    ))}
                </main>
                <NewsEventsSidebar />
            </div>
        </div>
        <Footer />
        <ScrollToTopButton />
    </div>
  )
}

export default Events