import { NavLink } from 'react-router-dom';

import React, { useState, useEffect } from 'react';

function NewsEventsSidebar() {
  const [isFixed, setIsFixed] = useState(false);
  const threshold = 650; // Adjust the threshold value as needed

    useEffect(() => {
        
      const stickyDiv = document.getElementById('sticky-div'); // Replace 'sticky-div' with the
        const handleScroll = () => {
          if (window.scrollY > 500 && window.scrollY < 1900) {
            setIsFixed(true);
            stickyDiv.style.position = 'fixed';
            stickyDiv.style.top = `80px`;
            
            stickyDiv.style.width = '300px'
          } else {
            setIsFixed(false);
            stickyDiv.style.position = 'static';
            stickyDiv.style.width = '300px'
          }

          if (window.innerWidth > 993) {
            stickyDiv.style.right = '90px';
          } else {
            stickyDiv.style.right = "4vw"
            stickyDiv.style.width = '250px'
          }

          if (window.innerWidth < 768 ) {
            stickyDiv.style.position = "static";
            stickyDiv.style.width = '100%';
          }
        };
        


    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="sidebar-container">
      <div id="content">
        {/* Your content here */}
      </div>
      <div
        id="sticky-div"
        style={{
          margin: '0px',
          padding: '0px',
          position: isFixed ? 'fixed' : 'static',
          
          top: isFixed ? `${threshold}px` : 'auto',
        }}
      >
        <aside id='about-sidebar'>
          <ul>
            <li>
              <NavLink to="/news&events/news" className={({isActive}) => isActive ? "active-about-link about-links": "non-active-class about-links" }>
               News
              </NavLink>
            </li>
            <li>
              <NavLink to="/news&events/events" className={({isActive}) => isActive ? "active-about-link about-links": "non-active-class about-links" }>
                Events
              </NavLink>
            </li>
            <li>
              <NavLink to="/news&events/newsletter" className={({isActive}) => isActive ? "active-about-link about-links": "non-active-class about-links" }>
                Newsletter
              </NavLink>
            </li>
          </ul> 
        </aside>
      </div>
    </div>
  );
}

export default NewsEventsSidebar;
