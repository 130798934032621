import drAnwar from "../components/assets/drAnwar1.jpg"
import shAbdullatif from "../components/assets/shAbdullatif.jpg"
import samudeen from "../components/assets/saamudeen.jpeg"
import tijani from "../components/assets/tijani.jpg"
import shSalman from "../components/assets/salman.jpg"
import anas from "../components/assets/anas.jpg"
import tamim from "../components/assets/tamim.jpg"
import sakhr from "../components/assets/sakhr.jpg"
import profAbu from "../components/assets/abu.jpg"



const teamData = [
    {
        id: "1",
        name: 'Dr Abass Sakhr Alhassan',
        position: "Member",
        country: 'Ghana',
        description: [
            'Imaam and Chairman of Kosoa Council of Imam',
            'Dean College of Alhuda Islamic Studies and Arabic Language'
        ],
        about: [
            ""
        ],
        photograph: sakhr
    },
    {
        id: "2",
        name: 'Dr Tamim Abdul Wahhab',
        position: "Member",
        country: 'Ghana',
        description: [
            'Imaam and Chairman of Masid Botswe, Ghana',
            'Ghana Central Mosque, Madina',
            'Sharia expert and Dean of Islamic Studies',
            'Member of New Zealand Halal Board'
        ],
        about: [
            ""
        ],
        photograph: tamim
    },
    {
        id: "3",
        name: 'Sheikh Abdullatif Sheikh',
        position: "Member",
        country: 'Kenya',
        description: [
            'Founder and Director of Family Resource Center (FRC), Nairobi, Kenya',
            'Member of Kenya Bureau of Halal Certification'
        ],
        about: [
            ""
        ],
        photograph: shAbdullatif
    },
    {
        id: "4",
        name: 'Anass Mohammed Esq',
        position: "Member",
        country: 'Ghana',
        description: [
            'Barister at Law Supreme Court of Ghana',
            'MD at Afadjato Chambers, Accra, Ghana',
        ],
        about: [
            ""
        ],
        photograph: anas
    },
    {
        id: "5",
        name: 'Professor Dr Anwar Sahib',
        position: "Member",
        country: 'New Zealand',
        description: [
            'Imaam and Chairman of Masjid at-Taqwa',
            'A professor in Islam and Dean of Islamic Studies faculty of the International Open University',
            'President of the Federation of Muslim Scholars of New Zealand (FMSNZ)'
        ],
        about: [
            'Dr. MOHAMMAD ANWAR SAHIB al-Madani gained admission to Madinah University at the age of fourteen, where after completing high school, he completed a BA in Islamic Studies, as well as Master’s and PhD in Qur’anic Sciences.',

            'Dr. Anwar is the Imaam of Masjid Attaqwa, New Zealand as well as a founder-member and the Chairman of its parent body, At Taqwa Trust. He is also the President of the Federation of Muslim Scholars of New Zealand (FMSNZ) and lectures in Islamic Law at the International Open University where he is the Dean of its Islamic Studies faculty as well as the lecturer for Tafseer, Hadeeth and Aqeedah.',

            "Dr. Anwar has been invited to participate and speak in several academic conferences by Muslim scholars and organizations in Saudi Arabia, Kuwait, India, Bangladesh, Australia, New Zealand and Fiji. He has also been invited to speak on national television and radio shows in Saudi Arabia and other countries. The Shaykh has the academic works to his credit including 'Lamhaat Hawl Taareekh Juzur Fiji wa Adyaanihaa' (A Brief History of the Fiji Islands and its Religions), Halaal & Haraam Food, 'Mawaarid Ibn Hajar al-Asqalaanee fee Uloom-il-Qur’aan min Kitaab Fath-il-Baaree' (Sources of Qur’anic Science Used by Imaam Ibn Hajar in his Commentary on Saheeh al-Bukhaaree), etc"
        ],
        photograph: drAnwar
    },
    {
        id: "6",
        name: 'Hon. Bukari Tijani',
        position: "Member",
        country: 'Nigeria',
        description: [
            'Former FAO',
            'Director-General of the Agriculture and Consumer Protection Department and Regional Representative for Africa'
        ],
        about: ["Mr Bukar Tijani was the FAO Assistant Director-General and Regional Representative for Africa, from December 2013 to October 2018.",
        
        "From November 2018 he assumed office as FAO Assistant Director-General of the Agriculture and Consumer Protection Department. He started his professional career in 1978 as a Rural Agricultural Extension Worker. After his National Service with the Kano Agricultural and Rural Development Authority in the mid-1980s, he collaborated with IITA, Ibadan, on on-farm adaptive research for small-holder farms on rice, maize, cowpea, soybean and farming systems, which led to adoption of varieties utilized by farmers up to present day in Nigeria.", 
        
        "He held senior positions in a number of the institutions under the State and at national levels. These included General Manager of Fertilizer Blending Company, Director in an EC-assisted Programme, North-East Arid Zone Development Programme (NEAZDP), National Programme Coordinator for IFAD-assisted Community-Based Agriculture and Rural Development Programme (IFAD-CBARDP) and National Project Coordinator for the Third Fadama Development Project assisted by the World Bank in Nigeria. He had extensive experience on participatory Community driven agriculture and rural development approaches across Nigeria. He coordinated all donor-assisted AfDB, IsDB, BADEA, IFAD and World Bank programmes and projects including the National Programme for Food Security (NPFS) and the FAO-China-Nigeria tripartite agreement on South-South Cooperation.", 
        
        "From July 2011 to September 2013, Mr. Tijani was a Cabinet Minister of the Federal Republic of Nigeria where he served as the Minister of State for Agriculture and Rural Development."],
        photograph: tijani
    },
    {
        id: "7",
        name: 'Samudeen Yusif',
        position: "Executive Director",
        country: 'Ghana',
        description: [
            ''
        ],
        about: [
            ""
        ],
        photograph: samudeen
    },
    {
        id: "8",
        name: 'Sheikh Salman Mohammed Alhassan',
        position: "Member",
        country: 'Ghana',
        description: [
            'Imaam of Madina Accra',
            'Member of Ghana Peace Council',
            'Director of Al-Hudaibiya'
        ],
        about: [
            "Sheikh Salman Mohammed Alhassan is the Chairman, National Representative Assembly of Ahlussunna Wal Jama’a – Ghana, Chief Imam of Madina West (Zongo) and adjourning communities. Additionally, Sheikh Salman Alhassan is the Overseer Imam of Ashiyie and Fulani suburbs in Adenta MA and General Overseer Imam of Al- Hashim Mosque 2000 in Adabraka, Accra  and a Memeber of National Peace Council"
        ],
        photograph: shSalman
    },
    {
        id: "9",
        name: 'Prof. Abu Yaya',
        position: "Member",
        country: 'Ghana',
        description: [
            
        ],
        about: [
            "Prof. Abu Yaya obtained his first degree in Chemistry in 2002 at the University of Cape Coast (UCC), Ghana. He was then awarded a EU scholarship to do his masters in Materials Science & Engineering at the University of Aveiro, Portugal and Aalborg University, Denmark. And he was subsequently awarded a CNRS, scholarship in France where he obtained his PhD in Nanomaterials Physics at the Institute of Materials in Nantes, University of Nantes, France in 2011.",

            "He was again awarded a six month Postdoctoral fellowship at Cambridge University, UK, where he worked on the development of electroporcelain insulators from local raw materials in Ghana.",

            "Prof. Yaya's research involves the use of ab initio density functional modelling programme (AIMPRO) and experimental optical spectroscopic techniques to study several important examples of weak intermolecular interactions in carbon nanomaterials. At the quantum mechanical level, calculations give a reliable and improved understanding of the role and feature of weak intermolecular interactions, which cannot be accurately predicted by conventional methods such as classical interatomic potentials. Also, in order to fully verify the theoretical calculations experimental tool such as resonant Raman and FT-IR are used to shed light on these interactions. Some useful applications of these are for understanding charge transfer interactions, photoconductivity and sensors.",

            "Presently, Prof. Yaya is looking at the fabrication and characterization of polymer and other filler materials such as graphene, graphites, nanotubes and clays which has specialized applications such as electro-porcelain insulators, lightweight composites & sensors.",
        ],
        photograph: profAbu
    },
    
    
    // Add more services as needed
];



export default teamData