import React from 'react';
import { Helmet } from "react-helmet";
import Navbar from '../components/Navbar';
import Footer from "../components/Footer"
import "./styles/HomeStyles.css";
import NewsSection from '../components/NewsSection';
import AboutSection from '../components/AboutSection';
import MissionVision from '../components/MissionVision';
import ServicesSection from '../components/ServicesSection';
import CertifiedMembersSection from '../components/CertifiedMembersSection';
import BecomeAMemberSection from '../components/BecomeAMemberSection';
import HeroComponent from '../components/HeroSection';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ScrollToTopButton from '../components/ScrollToTopBtn';

const Home = () => {

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="homepage">
        <Helmet>
          <title>Home - Bureau of Halal Certification, Ghana</title>
        </Helmet>
        <Navbar />
        <HeroComponent />
        <NewsSection />
        <AboutSection />
        <MissionVision />
        <ServicesSection />
        <CertifiedMembersSection />
        <BecomeAMemberSection />
        <Footer />
        <ScrollToTopButton />
    </div>
  )
}

export default Home
