import React from 'react';
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';
import "./styles/AboutStyles.css";
import "./styles/AboutBoardMembersStyles.css"
import Navbar from '../components/Navbar';
import Header from "../components/HeaderSection";
import Footer from "../components/Footer";
import JoinSideBar from '../components/JoinSideBar';
import ScrollToTopButton from '../components/ScrollToTopBtn';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MdPlayArrow } from "react-icons/md"

const JoinApplicationForms = () => {

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
        <Helmet>
          <title>Application Forms - BHC Ghana</title>
        </Helmet>
        <Navbar />
        <Header />
        <div className='section-container main-about'>
            <div className="about-wrapper">
                <main className="main ">
                  <div className="main-sections governance">
                    <p>Companies seeking BHC-GH certification should complete the relevant online application form below. A confirmation e-mail will be sent upon the successful completion of the application along with a receipt for the application fee.</p>
                    <p>We would strongly advise that you download and read the application process guide for Butchers, Restaurants/Takeaways, and Caterers (see link below) carefully and familiarise yourself first with all the relevant information contained within.</p>

                    <p><strong>Once you are familiar with the application process, click below to download the application forms that matches your operations:</strong></p>
                  </div>

                  <div className="main-sections governance">
                    <ul>
                      <li>
                        <MdPlayArrow className="check" />
                        <div className="list-text">
                          <a href="/application_forms_beef-sheep-abbatoir.pdf" target="_blank" rel="noopener noreferrer">
                          Beef/Sheep Abattoir
                          </a>
                        </div>
                      </li>
                      <li>
                        <MdPlayArrow className="check" />
                        <div className="list-text">
                          <a href="/application_forms_paultry-abbatoir.pdf" target="_blank" rel="noopener noreferrer">
                          Paultry Abattoir
                          </a>
                        </div>
                      </li>
                      <li>
                        <MdPlayArrow className="check" />
                        <div className="list-text">
                          <a href="/application_forms_butchery.pdf" target="_blank" rel="noopener noreferrer">
                          Butchery
                          </a>
                        </div>
                      </li>
                      <li>
                        <MdPlayArrow className="check" />
                        <div className="list-text">
                          <a href="/application_forms_catering-kitchen.pdf" target="_blank" rel="noopener noreferrer">
                          Catering Kitchen
                          </a>
                        </div>
                      </li>
                      <li>
                        <MdPlayArrow className="check" />
                        <div className="list-text">
                          <a href="/application_forms_restaurant.pdf" target="_blank" rel="noopener noreferrer">
                          Restaurant
                          </a>
                        </div>
                      </li>
                      <li>
                        <MdPlayArrow className="check" />
                        <div className="list-text">
                          <a href="/application_forms_product-supplier.pdf" target="_blank" rel="noopener noreferrer">
                          Product Supplier
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="main-sections governance">
                    <p>After filling the forms, submit them together with the application <NavLink to="/join/fees&charges"><strong>fees</strong></NavLink> to the offices of BHC-GH. For further assistance, you can call the numbers below: </p>           
                  </div>
                  <div className="main-sections governance">
                    <ul>
                        <li>
                          <MdPlayArrow className="check" />
                          <div className="list-text">
                            <a className="contact-info-link" href="tel:+233302123456" target="_blank" rel="noopener noreferrer">
                              +233 53 409 2123
                            </a>
                          </div>
                        </li>
                        <li>
                          <MdPlayArrow className="check" />
                          <div className="list-text">
                            <a className="contact-info-link" href="tel:+233302123456" target="_blank" rel="noopener noreferrer">
                              +233 30 254 1267
                            </a>
                          </div>
                        </li>
                      </ul>
                  </div>
                  
                  <p><strong>Please note, BHC-GH is working around the clock to change its application to a fully online process.</strong></p>
                </main>
                <JoinSideBar />
            </div>
        </div>
        <Footer />
        <ScrollToTopButton />
    </div>
  )
}

export default JoinApplicationForms