import React from 'react';
import { Helmet } from "react-helmet";
import "./styles/AboutStyles.css";
import "./styles/AboutBoardMembersStyles.css"
import Navbar from '../components/Navbar';
import Header from "../components/HeaderSection";
import Footer from "../components/Footer";
import JoinSideBar from '../components/JoinSideBar';
import ScrollToTopButton from '../components/ScrollToTopBtn';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BsCheckCircleFill } from "react-icons/bs";


const JoinGetCertified = () => {

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <div>
        <Helmet>
          <title>Benefits of Joining BHC Ghana</title>
        </Helmet>
        <Navbar />
        <Header />
        <div className='section-container main-about'>
            <div className="about-wrapper">
                <main className="main ">
                  <div className="main-sections governance">
                    <p>Over the years, the demand for Halal certified products has seen a significant surge in Ghana. The global market for Halal products is estimated to be valued at over £230bn, with the Ghanaian market alone being valued between £4.2bn – £5bn.</p>

                    <p>Muslims in Ghana, constituting approximately 3% of the population (2.5 million people), consume 20% of all meat. For Muslims, consuming Halal is a fundamental aspect of their faith, leaving them with no other option. With an increasing awareness of Halal issues, consumers are seeking additional assurance before consuming meat, requiring suppliers to be certified by an independent organization that has verified the meat as Halal.</p>

                    <p>Moreover, many Muslim-majority countries, such as Malaysia, the United Arab Emirates, and Saudi Arabia, only accept exported meat that has undergone a rigorous vetting process.</p>

                    <p>Numerous companies in Ghana continue to opt for BHC-GH Halal certification, benefiting from the marketing advantages that come with offering rigorously verified meat.</p>
                  </div>
                  <div className="main-sections governance">
                   <h2>Benefits of BHC-GH Certification</h2>
                   <ul>
                    <li>
                      <BsCheckCircleFill className="check" />
                      <div className="list-text">
                        BHC-GH certified products are accepted by all Muslim communities (our standards do not include stunning and mechanical slaughter).
                      </div>
                    </li>
                    <li>
                      <BsCheckCircleFill className="check" />
                      <div className="list-text">
                        BHC-GH certification gives Muslim customers the ‘peace of mind’ that they seek.
                      </div>
                    </li>
                    <li>
                      <BsCheckCircleFill className="check" />
                      <div className="list-text">
                        BHC-GH outlet owners see increase footfall and sales as a result of certification (they seek the direct benefits of Trust that consumers seek).
                      </div>
                    </li>
                    <li>
                      <BsCheckCircleFill className="check" />
                      <div className="list-text">
                        BHC-GH provides access to the vast potential of Halal market on a global scale.
                      </div>
                    </li>
                    <li>
                      <BsCheckCircleFill className="check" />
                      <div className="list-text">
                        BHC-GH's unique inspection and monitoring process provides export opportunities to the global market, comprising of approximately 1.6 billion Muslim consumers.
                      </div>
                    </li>
                    <li>
                      <BsCheckCircleFill className="check" />
                      <div className="list-text">
                        BHC-GH monitoring system complies with UK Law and all health, safety and hygiene practices.
                      </div>
                    </li>
                   </ul>
                  </div>
                </main>
                <JoinSideBar />
            </div>
        </div>
        <Footer />
        <ScrollToTopButton />
    </div>
  )
}

export default JoinGetCertified