import React from 'react';
import { Helmet } from "react-helmet";
import "./styles/AboutStyles.css";
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import Header from "../components/HeaderSection";
import { BsCheckCircleFill } from "react-icons/bs";
import SideBar from '../components/AboutSideBar';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ScrollToTopButton from '../components/ScrollToTopBtn';



const AboutStructure = () => {

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <div>
      <Helmet>
        <title>Governance Structure - BHC Ghana</title>
      </Helmet>
      <Navbar />
      <Header />
      <div className="section-container main-about">
        <div className="about-wrapper">
        <main className="main">
          <div className="main-sections governance">
            <p>Our Governance structure involve various levels of leadership and decision-making to ensure proper management and execution of our activities. Our Leadership adheres strictly to ethical principles and transparency, given the significance of Halal practices in various industries, such as food, cosmetics, pharmaceuticals, among others we serve.</p>
            <p>Below is the an outline of our governance structure:</p>
          </div>
          <div className="main-sections governance">
            <h2>Board of Directors (BOD)</h2>
            <ul>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  The highest level of governance, responsible for strategic planning, policy formulation, and major decision-making.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Comprises experienced professionals, industry experts, and stakeholders.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Sets the company's mission, vision, and long-term goals.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Oversees the CEO and executive management team.
                </div>
              </li>
            </ul>
          </div>
          <div className="main-sections governance">
            <h2>Chief Executive Officer (CEO)</h2>
            <ul>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Responsible for the overall management and administration of the firm.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Implements the strategies and decisions of the Board of Directors.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Leads the executive management team.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Represents the firm in external matters.
                </div>
              </li>
            </ul>
          </div>
          <div className="main-sections governance">
            <h2>Executive Management Team</h2>
            <ul>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Includes top executives responsible for various functional areas, such as operations, finance, marketing, and quality assurance.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Reports to the CEO and implements the company's strategies.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Coordinates the firm's daily operations.
                </div>
              </li>
            </ul>
          </div>
          <div className="main-sections governance">
            <h2>Advisory Board</h2>
            <ul>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Comprises industry experts, scholars, and professionals in the field of Halal certification and related industries.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Provides guidance and advice on technical matters, industry trends, and best practices.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Assists in maintaining the firm's credibility and staying up-to-date with developments in Halal practices.
                </div>
              </li>
            </ul>
          </div>
          <div className="main-sections governance">
            <h2>Halal Experts and Scholars</h2>
            <ul>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  May not be part of the official governance structure but play a critical role as consultants.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Offer expertise in Islamic jurisprudence (fiqh) and Halal standards.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Provide guidance on complex issues related to Halal compliance and certification.
                </div>
              </li>
            </ul>
          </div>
          <div className="main-sections governance">
            <h2>Halal Certification and Compliance Committee</h2>
            <ul>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Responsible for developing and reviewing certification standards and procedures.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Ensures that all products and processes meet the required Halal criteria.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  May include experts in food science, Islamic studies, and quality assurance.
                </div>
              </li>
            </ul>
          </div>
          <div className="main-sections governance">
            <h2>Operations Teams</h2>
            <ul>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Responsible for carrying out audits, inspections, and certification processes.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Includes auditors, inspectors, and technical experts.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Ensures that the firm's services align with Halal standards and industry regulations.
                </div>
              </li>
            </ul>
          </div>
          <div className="main-sections governance">
            <h2>Marketing and Business Development Team</h2>
            <ul>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Promotes the firm's services to potential clients.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Identifies opportunities for growth and expansion.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Collaborates with clients to understand their Halal certification needs.
                </div>
              </li>
            </ul>
          </div>
          <div className="main-sections governance">
            <h2>Training and Consultation Team</h2>
            <ul>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Develops training programs and workshops on Halal practices.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Provides consulting servics to clients seeking Halal certification.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Helps clients implement and maintain Halal standards in their operations.
                </div>
              </li>
            </ul>
          </div>
          <div className="main-sections governance">
            <h2>Quality Assurance and Compliance Team</h2>
            <ul>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Monitors and evaluates the firm's adherence to industry standards and regulatory requirements.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Ensures that certification processes are transparent, consistent, and accurate.
                </div>
              </li>
              <li>
                <BsCheckCircleFill className="check" />
                <div className="list-text">
                  Collaborates with clients to understand their Halal certification needs.
                </div>
              </li>
            </ul>
          </div>
        </main>
        <SideBar />
        </div>
      </div>
      <Footer />
      <ScrollToTopButton />
    </div>
  )
}

export default AboutStructure
