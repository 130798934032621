import React from 'react'
import MainLogoTitle from './MainLogoTitle';
import HeaderContactDetails from './HeaderContactDetails';

const MainNavbarHeader = () => {
  return (
    <div className="main-navbar-header">
        <MainLogoTitle />
        <HeaderContactDetails />
    </div>
  )
}

export default MainNavbarHeader