// Navbar.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import backgroundImage from '../components/assets/bgfinal.png';
import "./styles/HeaderSectionStyles.css"


function HeaderSection() {
    const headerContainerStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#eef3ec',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%'
      };

    const location = useLocation();

    // Map routes to their corresponding text
    const routeToText = {
        '/': 'WELCOME',
        '/about': 'About BHC-Ghana',
        '/about/about-bhcghana': 'About BHC-Ghana',
        '/about/structure': 'Governance Structure',
        '/about/team': 'Governing Board',
        '/about/team/all-members': 'All Members',
        '/about/team/board-members': 'Board Members',
        '/members': 'BHC-GH Certified Members',
        '/join/benefits': 'Benefits',
        '/join/application-forms': 'Application Forms',
        '/join/application-guide': 'Application Guide',
        '/join/fees&charges': 'Fees and Charges',
        '/resources': 'RESOURCES',
        '/news&events/news': 'News',
        '/news&events/events': 'Events',
        '/news&events/newsletter': 'Newsletter',
        '/contact': 'CONTACT BHC-GH',
        '/donate': 'Support BHC-GH',
    };

    const isNewsItemPage = location.pathname.includes('/news&events/news/');
    const isAboutTeamPage = location.pathname.includes('/about/team/');

    // Set the label based on whether it's a news item page, about team page, or other routes
    let currentPageText = routeToText[location.pathname] || '';

    if (isNewsItemPage) {
        currentPageText = 'News';
    } else if (isAboutTeamPage) {
        currentPageText = 'Governing Board';
    }

    return (
        <div className="header-container" style={headerContainerStyle}>
            <div className="section-container page-header">
                <div className="header-title">{currentPageText}</div>
            </div>
        </div>
    );
}

export default HeaderSection;
