// Blog.js
import React from 'react';
import Navbar from '../components/Navbar';
import Header from "../components/HeaderSection";
import Footer from "../components/Footer";
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import "./styles/NewsStyles.css"
import { getTeamMemberById } from '../components/TeamService'; // Import the function from your data service module


function TeamMember() {
  // Get the blog ID from the URL
  const { memberId } = useParams();

  // Use the blogId to fetch and display the blog content
  // You can fetch data from an API or use a data source as per your setup
  const teamMemberContent = getTeamMemberById(memberId);

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
        <Navbar />
        <Header />
        <div className='section-container main-about'>
            <div className="about-wrapper">
                <main className="main ">
                    <div className="board-member-cards-container board-member-cards-container-main">
                        <div className="board-member-card board-member-card-main">
                            <div className="board-member-img">
                                <img src={teamMemberContent.photograph} alt={teamMemberContent.name} />
                            </div>
                            <div className="board-member-details">
                                <h3 className="board-member-details-name">{teamMemberContent.name}</h3>
                                <p className="country">{teamMemberContent.position}</p>
                                <ul className="board-member-description-list">
                                    {teamMemberContent.description.map((line, i) => (
                                    <span key={i}>
                                        <li><span dangerouslySetInnerHTML={{ __html: line }} /></li>
                                    </span>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="board-member-details-about">
                            <h3>About {teamMemberContent.name}</h3>
                            {teamMemberContent.about.map((line, i) => (
                                    <span key={i}>
                                        <p><span dangerouslySetInnerHTML={{ __html: line }} /></p>
                                    </span>
                                    ))}
                            
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <Footer />
    </div>
  );
}

export default TeamMember;
