import React from 'react';
import { Helmet } from "react-helmet";
import "./styles/MembersStyles.css";
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import Header from "../components/HeaderSection";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ScrollToTopButton from '../components/ScrollToTopBtn';

const Members = () => {

  const location = useLocation();

  // Scroll to the top on route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <div>
      <Helmet>
        <title>Certified Members - BHC Ghana</title>
      </Helmet>
      <Navbar />
        <Header />
        <div className='section-container main-about'>
            <div className="about-wrapper">
                <main className="main ">
                    <div className="board-member-cards-container board-member-cards-container-main">
                    <p>BHC-GHANA is certifying businesses and organizations in Ghana. List of those who have received our certification will be featured in this section of our homepage, which will be updated soon.</p>
                    <p>Please check back later.</p>
                    </div>
                </main>
            </div>
        </div>
        <Footer />
        <ScrollToTopButton />
    </div>
  )
}

export default Members
