import React from 'react';
import "./styles/BecomeAMemberSectionStyles.css"
import pack from "./assets/join.jpg"
import StartApplicationBtn from './StartApplicationBtn';
import CheckCertificationBtn from './CheckCertificationBtn';


const BecomeAMemberSection = () => {
  
  return (
    <div className="section-container get-certified" >
        <div className="certified-section-wrapper">
            <div className="learn-more">
                <div className="pack-container">
                    <img className="pack-img" src={pack} alt="Pre application pack" />
                </div>
            </div> 
            <div className="get-certified-text">
                
                <p className="process-title">Our Certification process is smooth and straightforward</p>
                <div className="application-process-container">
                    <div className="application-process">
                        <div className="number-container">
                            <div className='number'>1</div>
                        </div>
                        <div className="process">
                            <h3>Contact Us</h3>
                            <p>All applicants may apply to the BHC-GH, during office hours, Mondays to Fridays</p>
                        </div>
                    </div>
                    <div className="application-process">
                        <div className="number-container">
                            <div className='number'>2</div>
                        </div>
                        <div className="process">
                            <h3>We follow the process</h3>
                            <p>All administrative details, as per Application Form, must be in order and correct.</p>
                        </div>
                    </div>
                    <div className="application-process">
                        <div className="number-container">
                            <div className='number'>2</div>
                        </div>
                        <div className="process last-process">
                            <h3>You get certified</h3>
                            <p>Annual Fee paid by client, then Issued with certificate</p>
                        </div>
                    </div>
                </div>
                <div className="apply-checkstat-btns process-btns">
                    <StartApplicationBtn />
                    <CheckCertificationBtn />
                </div>
            </div> 
        </div>
    </div>
  );
};

export default BecomeAMemberSection;